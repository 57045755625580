import { RouteConfig } from 'vue-router'

const routeName = 'ucc'

export const uccPageNames = {
    CONFIRM_DOB: 'confirm_dob',
    STATED_USAGE: 'stated_usage',
    PERSONAL_INFO: 'personal_info',
    STATED_INCOME: 'stated_income',
    ASSET_VERIFICATION: 'asset_verification',
    OFFER_PREVIEW: 'offer_preview',
    PRE_QUALIFICATION: 'pre_qualification',
    VERIFY_IDENTITY: 'identity',
    CO_APPLICANT_VERIFY_IDENTITY: 'co_applicant_identity',
    IDENTITY_QUESTIONS: 'identity_questions',
    CO_APPLICANT_IDENTITY_QUESTIONS: 'co_applicant_identity_questions',
    EMPLOYMENT_INCOME_VERIFICATION: 'employment_income_verification',
    CO_APPLICANT_EMPLOYMENT_INCOME_VERIFICATION: 'co_applicant_employment_income_verification',
    INCOME_VERIFICATION: 'income_verification',
    IRS_FORM_VERIFICATION: 'irs_form_verification',
    PAY_STUB_VERIFICATION: 'verify_pay_stubs',
    FORM_1099_VERIFICATION: 'verify_1099',
    TAX_RETURN_VERIFICATION: 'verify_tax_return',
    OTHER_INCOME_VERIFICATION: 'verify_other_income',
    FORTIFIED_INCOME_VERIFICATION: 'verify_fortified_income',
    RENTAL_INCOME_VERIFICATION: 'rental_income_verification',
    CURRENT_RENT_VERIFICATION_SELECTION: 'current_rent_verification_selection',
    CURRENT_RENT_VERIFICATION_DOCUMENT_UPLOAD: 'current_rent_verification_document_upload',
    INCOME_VERIFICATION_IN_PROGRESS: 'verify_in_progress_income',
    INCOME_DOCUMENT_UPLOAD_PORTAL: 'income_document_upload_portal',
    AWAITING_MANUAL_DOCUMENT_VERIFICATION: 'awaiting_manual_document_verification',
    SELECT_INCOME_VERIFICATION_METHOD: 'select_income_verification_method',
    EXPERIAN_FROZEN: 'credit_report_frozen',
    CO_APPLICANT_EXPERIAN_FROZEN: 'co_applicant_experian_frozen',
    SSN_VERIFICATION: 'ssn_verification',
    CO_APPLICANT_SSN_VERIFICATION: 'co_applicant_ssn_verification',
    IRS_FOR_ALL_PQ: 'irs_pre_qualification',
    IRS_FOR_ALL_IV: 'irs_income_verification',
    SELF_EMPLOYMENT_BUSINESS_PERCENTAGE: 'self_employment_business_percentage',
    WELCOME: 'welcome',
} as const

export type UccPageNameKeys = keyof typeof uccPageNames
export type UccPageNameValues = typeof uccPageNames[UccPageNameKeys]

export const uccPagePaths = {
    CONFIRM_DOB: `/${routeName}/${uccPageNames.CONFIRM_DOB}`,
    STATED_USAGE: `/${routeName}/${uccPageNames.STATED_USAGE}`,
    PERSONAL_INFO: `/${routeName}/${uccPageNames.PERSONAL_INFO}`,
    STATED_INCOME: `/${routeName}/${uccPageNames.STATED_INCOME}`,
    ASSET_VERIFICATION: `/${routeName}/${uccPageNames.ASSET_VERIFICATION}`,
    OFFER_PREVIEW: `/${routeName}/${uccPageNames.OFFER_PREVIEW}`,
    PRE_QUALIFICATION: `/${routeName}/${uccPageNames.PRE_QUALIFICATION}`,
    VERIFY_IDENTITY: `/${routeName}/${uccPageNames.VERIFY_IDENTITY}`,
    CO_APPLICANT_VERIFY_IDENTITY: `/${routeName}/${uccPageNames.CO_APPLICANT_VERIFY_IDENTITY}`,
    IDENTITY_QUESTIONS: `/${routeName}/${uccPageNames.IDENTITY_QUESTIONS}`,
    CO_APPLICANT_IDENTITY_QUESTIONS: `/${routeName}/${uccPageNames.CO_APPLICANT_IDENTITY_QUESTIONS}`,
    EMPLOYMENT_INCOME_VERIFICATION: `/${routeName}/${uccPageNames.EMPLOYMENT_INCOME_VERIFICATION}`,
    INCOME_VERIFICATION: `/${routeName}/${uccPageNames.INCOME_VERIFICATION}`,
    IRS_FORM_VERIFICATION: `/${routeName}/${uccPageNames.IRS_FORM_VERIFICATION}`,
    PAY_STUB_VERIFICATION: `/${routeName}/${uccPageNames.PAY_STUB_VERIFICATION}`,
    FORM_1099_VERIFICATION: `/${routeName}/${uccPageNames.FORM_1099_VERIFICATION}`,
    TAX_RETURN_VERIFICATION: `/${routeName}/${uccPageNames.TAX_RETURN_VERIFICATION}`,
    OTHER_INCOME_VERIFICATION: `/${routeName}/${uccPageNames.OTHER_INCOME_VERIFICATION}`,
    FORTIFIED_INCOME_VERIFICATION: `/${routeName}/${uccPageNames.FORTIFIED_INCOME_VERIFICATION}`,
    RENTAL_INCOME_VERIFICATION: `/${routeName}/${uccPageNames.RENTAL_INCOME_VERIFICATION}`,
    CURRENT_RENT_VERIFICATION_SELECTION: `/${routeName}/${uccPageNames.CURRENT_RENT_VERIFICATION_SELECTION}`,
    CURRENT_RENT_VERIFICATION_DOCUMENT_UPLOAD: `/${routeName}/${uccPageNames.CURRENT_RENT_VERIFICATION_DOCUMENT_UPLOAD}`,
    INCOME_VERIFICATION_IN_PROGRESS: `/${routeName}/${uccPageNames.INCOME_VERIFICATION_IN_PROGRESS}`,
    INCOME_DOCUMENT_UPLOAD_PORTAL: `/${routeName}/${uccPageNames.INCOME_DOCUMENT_UPLOAD_PORTAL}`,
    AWAITING_MANUAL_DOCUMENT_VERIFICATION: `/${routeName}/${uccPageNames.AWAITING_MANUAL_DOCUMENT_VERIFICATION}`,
    SELECT_INCOME_VERIFICATION_METHOD: `/${routeName}/${uccPageNames.SELECT_INCOME_VERIFICATION_METHOD}`,
    EXPERIAN_FROZEN: `/${routeName}/${uccPageNames.EXPERIAN_FROZEN}`,
    CO_APPLICANT_EXPERIAN_FROZEN: `/${routeName}/${uccPageNames.CO_APPLICANT_EXPERIAN_FROZEN}`,
    SSN_VERIFICATION: `/${routeName}/${uccPageNames.SSN_VERIFICATION}`,
    CO_APPLICANT_SSN_VERIFICATION: `/${routeName}/${uccPageNames.CO_APPLICANT_SSN_VERIFICATION}`,
    IRS_FOR_ALL_PQ: `/${routeName}/${uccPageNames.IRS_FOR_ALL_PQ}`,
    IRS_FOR_ALL_IV: `/${routeName}/${uccPageNames.IRS_FOR_ALL_IV}`,
    SELF_EMPLOYMENT_BUSINESS_PERCENTAGE: `/${routeName}/${uccPageNames.SELF_EMPLOYMENT_BUSINESS_PERCENTAGE}`,
    DOCUSIGN: `/${routeName}/docusign`,
    WELCOME_PAGE: `/${routeName}/${uccPageNames.WELCOME}`,
    START_KBA: `/${routeName}/start_kba`,
    KBA_QUESTIONS: `/${routeName}/kba_questions`,
    FLOW_COMPLETE: `/${routeName}/flow_complete`,
}

export const uccRoutes: RouteConfig[] = [
    {
        path: uccPagePaths.STATED_USAGE,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/StatedUsageUcc.vue'),
        props: true,
    },
    {
        path: uccPagePaths.PERSONAL_INFO,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/PersonalInfo.vue'),
        props: {
            allowJointForm: false,
            isUcc: true,
        },
    },
    {
        path: uccPagePaths.ASSET_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/PersonalAssetVerification.vue'),
        props: {
            isUcc: true,
        },
    },
    {
        path: uccPagePaths.SSN_VERIFICATION,
        component: () => import(/* webpackChunkName: "mailerOrigination" */ '@/pages/origination/SSNVerification.vue'),
    },
    {
        path: uccPagePaths.CO_APPLICANT_SSN_VERIFICATION,
        component: () => import(/* webpackChunkName: "mailerOrigination" */ '@/pages/origination/SSNVerification.vue'),
        props: { isCoApplicant: true },
    },
    {
        path: uccPagePaths.CO_APPLICANT_VERIFY_IDENTITY,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/VerifyIdentity.vue'),
        props: { isCoApplicant: true },
    },
    {
        path: uccPagePaths.CO_APPLICANT_IDENTITY_QUESTIONS,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/IdentityQuestions.vue'),
        props: { isCoApplicant: true },
    },
    {
        path: uccPagePaths.CO_APPLICANT_EXPERIAN_FROZEN,
        component: () => import(/* webpackChunkName: "coOrigination" */ '@/pages/origination/ExperianFrozen.vue'),
        meta: { transitionName: 'slide' },
        props: { isCoApplicant: true },
    },
    {
        path: uccPagePaths.PRE_QUALIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/PreQualification.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: uccPagePaths.OFFER_PREVIEW,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/OfferPreview.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: uccPagePaths.AWAITING_MANUAL_DOCUMENT_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/AwaitingManualDocumentVerification.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: uccPagePaths.PAY_STUB_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/PayStubVerification.vue'),
    },
    {
        path: uccPagePaths.FORM_1099_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/Form1099Verification.vue'),
    },
    {
        path: uccPagePaths.TAX_RETURN_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/TaxReturnVerification.vue'),
    },
    {
        path: uccPagePaths.OTHER_INCOME_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/OtherIncomeStatementUpload.vue'),
    },
    {
        path: uccPagePaths.FORTIFIED_INCOME_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/FortifiedIncomeVerification.vue'),
    },
    {
        path: uccPagePaths.INCOME_VERIFICATION_IN_PROGRESS,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/IncomeVerificationInProgress.vue'),
    },
    {
        path: uccPagePaths.INCOME_DOCUMENT_UPLOAD_PORTAL,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/IncomeDocumentUploadPortal.vue'),
    },
    {
        path: uccPagePaths.STATED_INCOME,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/StatedIncome.vue'),
    },
    {
        path: uccPagePaths.EXPERIAN_FROZEN,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/ExperianFrozen.vue'),
    },
    {
        path: uccPagePaths.VERIFY_IDENTITY,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/VerifyIdentity.vue'),
    },
    {
        path: uccPagePaths.IDENTITY_QUESTIONS,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/IdentityQuestions.vue'),
    },
    {
        path: uccPagePaths.SELECT_INCOME_VERIFICATION_METHOD,
        redirect: () => {
            return { path: uccPagePaths.EMPLOYMENT_INCOME_VERIFICATION }
        },
    },
    {
        path: uccPagePaths.EMPLOYMENT_INCOME_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/EmploymentIncomeVerification.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: uccPagePaths.INCOME_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/IncomeVerification.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: uccPagePaths.RENTAL_INCOME_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/nonOwnerOccupied/RentalIncomeVerification.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: uccPagePaths.CURRENT_RENT_VERIFICATION_SELECTION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/nonOwnerOccupied/CurrentRentVerificationSelection.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: uccPagePaths.CURRENT_RENT_VERIFICATION_DOCUMENT_UPLOAD,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/nonOwnerOccupied/CurrentRentVerificationDocumentUpload.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: uccPagePaths.IRS_FOR_ALL_PQ,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/IrsForAll.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: uccPagePaths.IRS_FOR_ALL_IV,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/IrsForAll.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: uccPagePaths.SELF_EMPLOYMENT_BUSINESS_PERCENTAGE,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/SelfEmploymentBusinessPercentage.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: uccPagePaths.DOCUSIGN,
        component: () => import(/* webpackChunkName: "ucc" */ '@/pages/shared/DocusignView.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: uccPagePaths.START_KBA,
        component: () => import(/* webpackChunkName: "ucc" */ '@/pages/kba/StartKba.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: uccPagePaths.KBA_QUESTIONS,
        component: () => import(/* webpackChunkName: "ucc" */ '@/pages/kba/KbaQuestions.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: uccPagePaths.WELCOME_PAGE,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/ucc/UccWelcomePage.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: uccPagePaths.FLOW_COMPLETE,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/shared/ThanksPage.vue'),
        props: { reason: 'uccSuccess' },
        meta: { transitionName: 'slide' },
    },
]
