// Polyfill
import 'intersection-observer'
import './utils/polyfillRouter'

// Set logging prototype as soon as possible
import Vue from 'vue'
import { logger } from '@/utils/logger'
Vue.prototype.$logEvent = window.logEvent
Vue.prototype.$logger = logger // we add logger to the prototype so that we can access it in aven_shared
Vue.prototype.$logMloLoanEvent = window.logMloLoanEvent

import App from './App.vue'
import NetworkUnavailable from './NetworkUnavailable.vue'
import router from './routes/router'
import './utils/validation'
import './utils/exception-handler'
import { i18n } from './utils/i18n'
import {
    initializeSessionRecording,
    initLinkedInPixel,
    initMicrosoftPixel,
    initNextDoor,
    initReddit,
    initTikTokPixel,
    initTrustPilot,
    initTwitterPixel,
    initTvSquaredPixel,
    initZendeskScript,
    initMNTNPixel,
    initTvScientificPixel,
} from './services/marketing'
import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { fireSessionIdRequest, isSessionDataPresent } from '@/services/sessionService'
import { isStateStale } from '@/utils/stateUtils'
import { experimentMarketingPageRoutes } from '@/experiments/src/routes/marketingRoutes'
import { initGooglePlacesClient } from '@/services/googlePlaces'
import { maybeTryTrackClick } from '@/utils/clickTracking'
import { initFingerprintAndUpdateAnalytics } from '@/services/fingerprint'
import { redirections } from '@/services/redirections'
import { initGlobalMixin } from '@/config/initGlobalMixin'
import { initPlugins } from '@/config/initPlugins'

if (window.prerender) {
    // Prevent network errors from being thrown during prerender mode
    logger.setNetworkLogging(false)
}

initPlugins(Vue)
initGlobalMixin(Vue)

let isInitialized = false
const initVueAndThirdpartyServices = () => {
    // Don't let components get initialized twice (this function could be fired twice for various reasons)
    if (isInitialized) {
        return
    }

    // Don't bother initializing third party services when we're in prerender mode
    if (!window.prerender) {
        // Init session recording ASAP
        initializeSessionRecording()
    }

    logger.info('Adding experiment marketing page routes')
    experimentMarketingPageRoutes().forEach((route) => router.addRoute(route))

    // Listen for + log when vue-loaded is fired (helps with debugging)
    document.addEventListener('vue-loaded', function () {
        logger.log('Vue loaded!')
    })

    logger.log('Enabling vue...')

    redirections(router)

    new Vue({
        router,
        i18n,
        render: (h) => h(App),
    }).$mount('#app')

    // Don't bother initializing third party services when we're in prerender mode
    if (!window.prerender) {
        initZendeskScript()
        initNextDoor()
        initReddit()
        initTrustPilot()
        initTikTokPixel()
        initMicrosoftPixel()
        initTwitterPixel()
        initLinkedInPixel()
        initTvSquaredPixel()
        initGooglePlacesClient().catch((error) => logger.error(`initGooglePlacesClient -> failed to init google places`, error))
        // Async call to get visitorId and update analytics without blocking main thread
        initFingerprintAndUpdateAnalytics().catch((error) => logger.error(`initFingerprintAndUpdateAnalytics -> failed to init fingerprintjs`, error))
        initMNTNPixel()
        initTvScientificPixel()
    }
    isInitialized = true

    // my god batman, are we logging an event once every second???
    // no robin, it's 10 seconds now. but we won't save it to the db like you think.
    setInterval(() => {
        window.logEvent('still_here')
    }, 10000)

    // This triggers the prerender script to capture the page
    // In normal modes, fires a message to the console
    const vueLoadedEvent = new Event('vue-loaded')
    document.dispatchEvent(vueLoadedEvent)
}

const initVueNetworkUnavailable = () => {
    if (isInitialized) {
        return
    }

    // Don't bother sending to the backend, unlikely to succeed + generates tons of console errors
    logger.setNetworkLogging(false)
    logger.log('Enabling vue (network unavailable)...')
    new Vue({
        i18n,
        render: (h) => h(NetworkUnavailable),
    }).$mount('#app')

    isInitialized = true
}

// Enable this to test 'prerender mode' in a normal browser
// window.prerender = { default: 'default20210802' }

// Detect if we're in prerender mode, if so short circuit some code paths
if (window.prerender) {
    // session ID MUST be set first
    appSessionStorage.setItem(sessionStorageKey.sessionId, 'prerender')
    appSessionStorage.setItem(sessionStorageKey.sessionAccessJWT, 'prerender')
}

function handleSessionInit(xmlHttpRequest: XMLHttpRequest, timeoutMs: number, errorCallback: () => void, successCallback: () => void) {
    const passiveWaitForSession = setTimeout(function () {
        if (!isSessionDataPresent()) {
            console.log('Took too long to load, aborting')
            xmlHttpRequest.abort()
            if (errorCallback) {
                errorCallback()
            }
        } else {
            console.log('Session ID loaded successfully')
            if (successCallback) {
                successCallback()
            }
        }
    }, timeoutMs)

    window.addEventListener(
        'sessionIdReady',
        function () {
            logger.log('handleSessionInit received sessionIdReady event')
            clearTimeout(passiveWaitForSession)
            if (successCallback) {
                successCallback()
            }
        },
        false
    )
}

function storeAbExperimentFromUrlIfPresent() {
    const urlParams = new URLSearchParams(window.location.search)
    const abExperimentType = urlParams.getAll('expType')
    const abExperimentGroup = urlParams.getAll('expGroup')
    if (abExperimentType.length !== abExperimentGroup.length) {
        console.log('Malformed experiment url params; ExperimentType and ExperimentGroup must be the same length')
    }

    if (abExperimentType.length > 0) {
        const abExperiment = abExperimentType.map((experimentType, index) => {
            return {
                type: experimentType,
                group: abExperimentGroup[index],
            }
        })
        appSessionStorage.setItem(sessionStorageKey.abTestOverrides, JSON.stringify(abExperiment))
    }
}

function isCleanReloadRequested() {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get('cleanReload') === 'true'
}

// *DO NOT CHANGE* This param is sent in comms by the backend.
// Key is defined in aven_backend/src/manager/clickTrackingManager.ts
const COMMUNICATION_SHORT_ID_QUERY_KEY = 'cm'

// *DO NOT CHANGE* This param is sent in comms by the backend.
// Key is defined in aven_backend/src/manager/clickTrackingManager.ts
const CLICK_TRACKING_LINK_TABLE_PREFIX = '-'

// *DO NOT CHANGE* This param is sent in comms by the backend.
// Key is defined in aven_backend/src/manager/clickTrackingManager.ts
const TRACKED_LINK_RADIX = 36

function init() {
    if (isStateStale() || isCleanReloadRequested()) {
        console.log(`Init hit with stale state, clearing appSessionStorage...`)
        appSessionStorage.clear()
        storeAbExperimentFromUrlIfPresent()
    } else if (isSessionDataPresent()) {
        // No need to run the rest of the logic, init vue and return
        console.log('sessionId, sessionAccessJWT and experimentName already set')
        storeAbExperimentFromUrlIfPresent()
        initVueAndThirdpartyServices()
        return
    }

    console.log('Waiting for sessionIdReady / networkUnavailable event...')

    window.addEventListener(
        'sessionIdReady',
        function () {
            logger.log('Received sessionIdReady event')
            initVueAndThirdpartyServices()
        },
        false
    )

    window.addEventListener(
        'networkUnavailable',
        function () {
            logger.log('Received networkUnavailable event')
            initVueNetworkUnavailable()
        },
        false
    )

    const initialUrl = window.location.href
    const queryParams = new URLSearchParams(window.location.search)
    const sessionIdReq = fireSessionIdRequest()
    handleSessionInit(
        sessionIdReq,
        5000,
        function () {
            const timesReloaded = Number.parseInt(queryParams.get('reloaded') || '0', 10)

            if (timesReloaded < 3) {
                console.log('Force reloading page because sessionId call failed, page already reloaded ' + timesReloaded + ' times')

                queryParams.set('reloaded', `${timesReloaded + 1}`)
                window.location.search = queryParams.toString()
            } else {
                console.log('Dispatching networkUnavailable event')
                const networkUnavailableEvent = new Event('networkUnavailable')
                window.dispatchEvent(networkUnavailableEvent)
            }
        },
        function () {
            logger.info(`Successfully initialized Aven session!`)
            // IMPORTANT: query param name and radix must remain in sync at all times with the backend
            // Note: we don't create new `tl` links anymore, but this code should remain to support the
            // links with `tl` param that we did create and are in the wild.
            let trackedLinkParamUsed = null
            if (queryParams.has('tl')) {
                trackedLinkParamUsed = 'tl'
            } else if (queryParams.has(COMMUNICATION_SHORT_ID_QUERY_KEY)) {
                trackedLinkParamUsed = COMMUNICATION_SHORT_ID_QUERY_KEY
            }
            if (trackedLinkParamUsed) {
                const trackedLinkIdStr = queryParams.get(trackedLinkParamUsed) as string

                if (trackedLinkParamUsed === COMMUNICATION_SHORT_ID_QUERY_KEY && trackedLinkIdStr.length === 12) {
                    // This is a communication short id, use legacy tracking
                    logger.info(`Found commShortId ${trackedLinkIdStr} in ${initialUrl}. Tracking click`)
                    maybeTryTrackClick(initialUrl).then(() => {
                        logger.info(`Tracked click on link ${initialUrl} in session init callback`)
                    })
                } else {
                    // new system. Fire an event to the backend
                    let trackedLinkId: number | undefined
                    let clickTrackingLinkId: number | undefined
                    if (trackedLinkIdStr.startsWith(CLICK_TRACKING_LINK_TABLE_PREFIX)) {
                        const encodedParam = trackedLinkIdStr.slice(1)
                        clickTrackingLinkId = Number.parseInt(encodedParam, TRACKED_LINK_RADIX)
                        logger.info(
                            `trackedLinkIdStr ${trackedLinkIdStr} starts with prefix ${CLICK_TRACKING_LINK_TABLE_PREFIX}, so storing clickTrackingLinkId=${clickTrackingLinkId} in tracked_link_click`
                        )
                    } else {
                        trackedLinkId = Number.parseInt(trackedLinkIdStr, TRACKED_LINK_RADIX)
                        logger.info(
                            `trackedLinkIdStr ${trackedLinkIdStr} does not start with prefix ${CLICK_TRACKING_LINK_TABLE_PREFIX}, so storing trackedLinkId=${trackedLinkId} in tracked_link_click`
                        )
                    }
                    const queryParamsObj: Record<string, string> = {}
                    queryParams.forEach((value: string, key: string) => {
                        queryParamsObj[key] = value
                    })
                    const properties = {
                        trackedLinkId,
                        clickTrackingLinkId,
                        link: initialUrl,
                        queryParams: queryParamsObj,
                    }
                    logger.info(`Logging tracked_link_click with properties ${JSON.stringify(properties)}`)
                    window.logEvent('tracked_link_click', properties)
                }
            }
        }
    )
}

init()
