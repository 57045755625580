"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SweepstakesType = exports.SweepstakesV2Type = exports.SweepstakesOriginalType = exports.SweepstakesStatus = void 0;
var SweepstakesStatus;
(function (SweepstakesStatus) {
    SweepstakesStatus["OPEN"] = "OPEN";
    SweepstakesStatus["CLOSED"] = "CLOSED";
})(SweepstakesStatus = exports.SweepstakesStatus || (exports.SweepstakesStatus = {}));
exports.SweepstakesOriginalType = {
    CASH: 'CASH',
    MORTGAGE: 'MORTGAGE',
    PLAID_TRANSACTION: 'PLAID_TRANSACTION', // means we are paying for people's plaid transactions up to some cap
};
exports.SweepstakesV2Type = {
    MORTGAGE_PAYMENT: 'MORTGAGE_PAYMENT',
    HOME_PAYMENT: 'HOME_PAYMENT',
};
exports.SweepstakesType = {
    ...exports.SweepstakesOriginalType,
    ...exports.SweepstakesV2Type,
};
